import React, { useState, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import config from "./config";
import hollerSrc from "../../assets/images/distillery-holler.jpg";
import barnSrc from "../../assets/images/distillery-barn.jpg";
import barrelhouseSrc from "../../assets/images/distillery-barrelhouse.jpg";
import nashvilleSrc from "../../assets/images/distillery-nashville.jpg";

import pin1Src from "../../assets/vectors/pin-1.svg";
import pin2Src from "../../assets/vectors/pin-2.svg";
import pin3Src from "../../assets/vectors/pin-3.svg";
import pin4Src from "../../assets/vectors/pin-4.svg";

const pins = [pin1Src, pin2Src, pin3Src, pin4Src];

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAGB0SYA_jDGZG27djpOQARlZJqw6X8ZoA",
  });

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  //const [infoWindow, setInfoWindow] = useState(null);

  const center = { lat: 35.71142, lng: -83.51986 };

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();

    const infoWindow = new window.google.maps.InfoWindow({
      content: "",
    });

    const markers = config.locations.map((location, idx) => {
      bounds.extend({ lat: location.lat, lng: location.lng });
      const marker = new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map,
        icon: {
          url: pins[idx],
        },
        title: location.label,
        data: location,
      });
      return marker;
    });

    markers.forEach((marker) => {
      marker.addListener("click", () => {
        markers.forEach((markerZIndexReset) =>
          markerZIndexReset.setZIndex(100)
        );
        marker.setZIndex(101);
        infoWindow.setContent(
          `<div>
            <h3 style="color: black">${marker.title}</h3>
            ${marker.data.addressLine1}<br />
            ${marker.data.addressLine2}<br />
            <a
              style="color: black; text-decoration: underline;"
              target="_blank"
              href="${marker.data.directionsLink}"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </div>`
        );
        infoWindow.open({
          anchor: marker,
          map,
        });
      });
    });

    map.fitBounds(bounds);
    setMarkers(markers);
    setMap(map);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <section className="section-map p-lr-164 p-tb-140">
      <div className="section-map__container">
        <div className="section-map__container--intro m-0-auto text-center">
          <h1 className="m-b-24">
            Available for purchase at Ole Smoky Distillery East TN locations,
            and limited distribution in liquor stores in TN, NC & SC
          </h1>
          <p className="m-0-auto">
            Discover Ole Smoky Distilleries in the heart of the Great Smoky
            Mountains. You'll find Popcorn Sutton products available at the
            Gatlinburg and Pigeon Forge Ole Smoky distillery locations, as well
            as available for purchase in liquor stores in TN, NC & SC.
          </p>
        </div>

        {isLoaded ? (
          <GoogleMap
            id="map"
            mapContainerClassName="section-map__container--map m-0-auto w-100 bg-img-default block"
            // mapContainerStyle={config}
            options={{
              mapId: "b5744eebd4b2e573",
              // styles: config.styles,
              disableDefaultUI: true,
              zoomControl: true,
            }}
            center={center}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        ) : null}

        <div className="section-map__container--details m-0-auto">
          <div className="container grid">
            {/* Distillery 1 --> */}
            <div className="location-block flex f-column">
              <button
                className="a"
                onClick={() => {
                  new window.google.maps.event.trigger(markers[0], "click");
                  document
                    .getElementById("map")
                    .scrollIntoView({ block: "start", behavior: "smooth" });
                }}
              >
                <img className="m-b-24" src={hollerSrc} alt="The Holler" />
                <div className="text-block text-center">
                  <div className="title flex f-row m-b-8">
                    <img src={pin1Src} />
                    <h2>The Holler</h2>
                  </div>

                  <div className="address">
                    903 Parkway #128,
                    <br />
                    Gatlinburg, TN 37738
                    <br />
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href="https://www.google.com/maps/place/Ole+Smoky+Moonshine/@35.711171,-83.5220779,17z/data=!4m15!1m8!3m7!1s0x885955c4e3ab1c43:0xc89d90fda0084a82!2s903+Parkway+%23128,+Gatlinburg,+TN+37738!3b1!8m2!3d35.711171!4d-83.519503!16s%2Fg%2F11q_64m61z!3m5!1s0x885955c51ef24c3b:0x4c534b356e99797a!8m2!3d35.711171!4d-83.519503!16s%2Fg%2F1tfstlkg?entry=ttu"
                      rel="noreferrer"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </button>
            </div>

            {/* Distillery 2 --> */}
            <div className="location-block flex f-column">
              <button
                className="a"
                onClick={() => {
                  new window.google.maps.event.trigger(markers[1], "click");
                  document
                    .getElementById("map")
                    .scrollIntoView({ block: "start", behavior: "smooth" });
                }}
              >
                <img className="m-b-24" src={barnSrc} alt="The Barn" />
                <div className="text-block text-center">
                  <div className="title flex f-row m-b-8">
                    <img src={pin2Src} />
                    <h2>The Barn</h2>
                  </div>

                  <div className="address">
                    131 The Island Dr #9116,
                    <br />
                    Pigeon Forge, TN 37863
                    <br />
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href="https://www.google.com/maps/place/Ole+Smoky+Moonshine+%22The+Barn%22/@35.8040768,-83.5764128,17z/data=!4m15!1m8!3m7!1s0x885bff6b52924d2f:0x77009f04bd580d66!2s131+The+Island+Dr,+Pigeon+Forge,+TN+37863!3b1!8m2!3d35.8040769!4d-83.5715473!16s%2Fg%2F11g2tz6jg1!3m5!1s0x885bff135545e73d:0xd113cbfff82b4917!8m2!3d35.8033809!4d-83.5709177!16s%2Fg%2F11b6ggbkjz?entry=ttu"
                      rel="noreferrer"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </button>
            </div>

            {/* Distillery 3 --> */}
            <div className="location-block flex f-column">
              <button
                className="a"
                onClick={() => {
                  new window.google.maps.event.trigger(markers[2], "click");
                  document
                    .getElementById("map")
                    .scrollIntoView({ block: "start", behavior: "smooth" });
                }}
              >
                <img
                  className="m-b-24"
                  src={barrelhouseSrc}
                  alt="The Barrelhouse"
                />
                <div className="text-block text-center">
                  <div className="title flex f-row m-b-8">
                    <img src={pin3Src} />
                    <h2>The Barrelhouse</h2>
                  </div>

                  <div className="address">
                    650 Parkway,
                    <br />
                    Gatlinburg, TN 37738
                    <br />
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href="https://www.google.com/maps/place/Ole+Smoky:+%22The+Barrelhouse%22+(Whiskey)/@35.7121277,-83.5189213,18z/data=!4m7!3m6!1s0x885955db743fc2cd:0x29efa856da44cfa4!8m2!3d35.7124388!4d-83.5152526!15sChs2NTAgcGFya3dheSBUaGUgQmFycmVsaG91c2VaHSIbNjUwIHBhcmt3YXkgdGhlIGJhcnJlbGhvdXNlkgEKZGlzdGlsbGVyeZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOeGFrbHFNMjlSUlJBQuABAA!16s%2Fg%2F11bbrpjn62?entry=tts"
                      rel="noreferrer"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </button>
            </div>

            {/* Distillery 4 --> */}
            {/* <div className="location-block flex f-column">
              <button
                className="a"
                onClick={() => {
                  new window.google.maps.event.trigger(markers[3], "click");
                  document
                    .getElementById("map")
                    .scrollIntoView({ block: "start", behavior: "smooth" });
                }}
              >
                <img
                  className="m-b-24"
                  src={nashvilleSrc}
                  alt="6th & Peabody"
                />
                <div className="text-block text-center">
                  <div className="title flex f-row m-b-8">
                    <img src={pin4Src} />
                    <h2>6th & Peabody</h2>
                  </div>

                  <div className="address">
                    423 6th Ave S, <br />
                    Nashville, TN 37203
                    <br />
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href="https://www.google.com/maps/place/6th+%26+Peabody/@36.1550188,-86.7794515,17z/data=!3m1!4b1!4m6!3m5!1s0x8864676e7ba201e5:0xad1e4650d5521a71!8m2!3d36.1550188!4d-86.7768712!16s%2Fg%2F11h6zr9dhz?entry=ttu"
                      rel="noreferrer"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Map;
