export default {
  locations: [
    {
      label: "The Holler",
      addressLine1: "903 Parkway #128",
      addressLine2: "Gatlinburg, TN 37738",
      lat: 35.71142,
      lng: -83.51986,
      directionsLink:
        "https://www.google.com/maps/place/Ole+Smoky+Moonshine/@35.711171,-83.5220779,17z/data=!4m15!1m8!3m7!1s0x885955c4e3ab1c43:0xc89d90fda0084a82!2s903+Parkway+%23128,+Gatlinburg,+TN+37738!3b1!8m2!3d35.711171!4d-83.519503!16s%2Fg%2F11q_64m61z!3m5!1s0x885955c51ef24c3b:0x4c534b356e99797a!8m2!3d35.711171!4d-83.519503!16s%2Fg%2F1tfstlkg?entry=ttu",
    },
    {
      label: "The Barn",
      addressLine1: "131 The Island Dr #9116",
      addressLine2: "Pigeon Forge, TN 37863",
      lat: 35.71142,
      lng: -83.57006,
      directionsLink:
        "https://www.google.com/maps/place/Ole+Smoky+Moonshine+%22The+Barn%22/@35.8040768,-83.5764128,17z/data=!4m15!1m8!3m7!1s0x885bff6b52924d2f:0x77009f04bd580d66!2s131+The+Island+Dr,+Pigeon+Forge,+TN+37863!3b1!8m2!3d35.8040769!4d-83.5715473!16s%2Fg%2F11g2tz6jg1!3m5!1s0x885bff135545e73d:0xd113cbfff82b4917!8m2!3d35.8033809!4d-83.5709177!16s%2Fg%2F11b6ggbkjz?entry=ttu",
    },
    {
      label: "The Barrelhouse",
      addressLine1: "650 Parkway",
      addressLine2: "Gatlinburg, TN 37738",
      lat: 35.7124,
      lng: -83.51526,
      directionsLink:
        "https://www.google.com/maps/place/Ole+Smoky:+%22The+Barrelhouse%22+(Whiskey)/@35.7121277,-83.5189213,18z/data=!4m7!3m6!1s0x885955db743fc2cd:0x29efa856da44cfa4!8m2!3d35.7124388!4d-83.5152526!15sChs2NTAgcGFya3dheSBUaGUgQmFycmVsaG91c2VaHSIbNjUwIHBhcmt3YXkgdGhlIGJhcnJlbGhvdXNlkgEKZGlzdGlsbGVyeZoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOeGFrbHFNMjlSUlJBQuABAA!16s%2Fg%2F11bbrpjn62?entry=tts",
    },
    // {
    //   label: "6th & Peabody",
    //   addressLine1: "423 6th Ave S",
    //   addressLine2: "Nashville, TN 37203",
    //   lat: 36.15495,
    //   lng: -86.77711,
    //   directionsLink:
    //     "https://www.google.com/maps/place/6th+%26+Peabody/@36.1550188,-86.7794515,17z/data=!3m1!4b1!4m6!3m5!1s0x8864676e7ba201e5:0xad1e4650d5521a71!8m2!3d36.1550188!4d-86.7768712!16s%2Fg%2F11h6zr9dhz?entry=ttu",
    // },
  ],
  // styles: [
  //   {
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#212121",
  //       },
  //     ],
  //   },
  //   {
  //     elementType: "labels.icon",
  //     stylers: [
  //       {
  //         visibility: "off",
  //       },
  //     ],
  //   },
  //   {
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#757575",
  //       },
  //     ],
  //   },
  //   {
  //     elementType: "labels.text.stroke",
  //     stylers: [
  //       {
  //         color: "#212121",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "administrative",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#757575",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "administrative.country",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#9e9e9e",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "administrative.land_parcel",
  //     stylers: [
  //       {
  //         visibility: "off",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "administrative.locality",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#bdbdbd",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "poi",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#757575",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "poi.park",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#181818",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "poi.park",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#616161",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "poi.park",
  //     elementType: "labels.text.stroke",
  //     stylers: [
  //       {
  //         color: "#1b1b1b",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road",
  //     elementType: "geometry.fill",
  //     stylers: [
  //       {
  //         color: "#2c2c2c",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#8a8a8a",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road.arterial",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#373737",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road.highway",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#3c3c3c",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road.highway.controlled_access",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#4e4e4e",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "road.local",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#616161",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "transit",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#757575",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "water",
  //     elementType: "geometry",
  //     stylers: [
  //       {
  //         color: "#000000",
  //       },
  //     ],
  //   },
  //   {
  //     featureType: "water",
  //     elementType: "labels.text.fill",
  //     stylers: [
  //       {
  //         color: "#3d3d3d",
  //       },
  //     ],
  //   },
  // ],
};
